import Connect, { windmillRun } from '../../../../Connect/Connect';
import { buildGlobalConstantsForEmailConfigs, buildGlobalConstantsForFieldMappings } from '../../../../IntegrationApps/IntegrationAppDetails/utils';

/**
 * Validate the authentication configurations for connection to Zuora
 *
 * @param {{ SAP_BILLING_CLIENT_ID: string, SAP_BILLING_CLIENT_SECRET: string, SAP_BILLING_REST_ENDPOINT: string}} auth - Authentication configurations
 */
export const validateZuora = async (
  auth: {
    SAP_BILLING_CLIENT_ID: string;
    SAP_BILLING_CLIENT_SECRET: string;
    SAP_BILLING_REST_ENDPOINT: string;
  }
) => {
  let result = {
    isValid: false,
    status: 200,
    statusText: ''
  };
  try {
    const response = await windmillRun({
      'script_path': 'f/sap_connector/flows',
      'params': {
        'action': 'validate_zuora_billing',
        'auth': auth
      },
      'async': false
    });
    result = response;
  } catch (error) {
    Connect.log(error);
  }
  return result;
}

/**
 * Build Workflow global constants for SAP billing
 *
 * @param {{ SAP_BILLING_CLIENT_ID: string, SAP_BILLING_CLIENT_SECRET: string, SAP_BILLING_REST_ENDPOINT: string}} auth - Authentication configurations
 * @param {{enabled: boolean, failures: boolean, success: boolean, emails: string[]}} emailNotifications - Email notification configurations
 * @param {any} fieldMappings - Field mappings between Zuora and SAP
 * @param {string} envRestEndpoint - The REST endpoint for a specific Zuora environment (prod, test, apisandbox, etc.)
 */
export const buildSAPBillingGlobalConstants = (auth: {
  SAP_BILLING_CLIENT_ID: string;
  SAP_BILLING_CLIENT_SECRET: string;
  SAP_BILLING_REST_ENDPOINT: string;
}, emailNotifications: {
  enabled: boolean;
  failures: boolean;
  success: boolean;
  emails: string[];
}, fieldMappings: any, envRestEndpoint: string) => {

  // build field mappings
  const mappings = buildGlobalConstantsForFieldMappings(fieldMappings);

  // build email configs
  const emailConfigs = buildGlobalConstantsForEmailConfigs(emailNotifications);

  return {
    "SAP_BILLING_CLIENT_ID": auth.SAP_BILLING_CLIENT_ID,
    "SAP_BILLING_CLIENT_SECRET": auth.SAP_BILLING_CLIENT_SECRET,
    "SAP_BILLING_REST_ENDPOINT": envRestEndpoint,
    "SAP_BILLING_MAPPINGS": JSON.stringify(mappings),
    "SAP_BILLING_EMAIL_CONFIG": JSON.stringify(emailConfigs),
  };
};