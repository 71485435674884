import { Button, Chip, Grid, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { useState } from 'react';
import { useStoreState } from '../../../../../Store';
import { IADState } from '../../../../../IntegrationApps/IntegrationAppDetails/state';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { AlertColor } from '@mui/material';
import { validateZuora } from '../utils';

export const HeaderTitle = ({
  header,
  subheader,
  showTestConnection,
  tabName
}) => {
  const state: IADState = useStoreState();
  const activeChip = state.active ? <Chip size='small' label={<Typography variant='overline'>Active</Typography>} state='success' /> : <Chip size='small' label={<Typography variant='overline'>Inactive</Typography>} state='indeterminate' />;
  const testConnectionClick = async () => {
    if (!loading) {
      setLoading(true);
      setToast({...toast, show: false});

      try {
        const zuora = await validateZuora({...state.settingsHash?.[tabName], SAP_BILLING_REST_ENDPOINT: state.envRestEndpoint});

        let msg = '';
        let severity = 'info' as AlertColor;

        if (!zuora.isValid) {
          msg = (
            <>
              Failed to validate Zuora credentials <br/>
              Zuora status code: {zuora.status} <br/>
              Zuora status text: {zuora.statusText} <br/>
            </>
          ) as any;
          severity = 'error';
        } else {
          msg = 'Successfully connected';
          severity = 'success';
        }

        setLoading(false);
        setToast({ show: true, msg, severity });
      } catch (error) {
        setLoading(false);
        setToast({ show: true, msg: 'Failed to connect to data sources. Please try again later.', severity: 'error' });
      }
    }
  };
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });

  return (
    <>
      <Grid container item xs direction='column' spacing={0.5}>
        <Grid container item alignItems='center' spacing={1}>
          <Grid item><Typography variant='h6'>{header}</Typography></Grid>
          <Grid item>{activeChip}</Grid>
        </Grid>
        <Grid item><Typography variant='body2'>{subheader}</Typography></Grid>
      </Grid>
      { toast.show && <ToastController severity={toast.severity} message={toast.msg} /> }
      {
        showTestConnection && 
        <div style={{paddingTop: '10px', paddingRight: '5px'}}>
          <Box sx={{m: 1, position: 'relative'}}>
            <Button
              disabled={
                loading || !state.active ||
                !state.settingsHash?.[tabName]?.['SAP_BILLING_CLIENT_ID'] ||
                !state.settingsHash?.[tabName]?.['SAP_BILLING_CLIENT_SECRET']
              }
              variant='text'
              children='Test Connection'
              dsOnClick={testConnectionClick}
            />
            {
              loading &&
              <CircularProgress
                size={24}
                sx={{
                  color: designTokens.colors.blue500,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            }
          </Box>
        </div>
      }
    </>
  );
};